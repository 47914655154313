// Header

// # Contents
// 1. Header Base
// 2. Header Elements
// 2.1) Logo
// 2.2) Header Contact
// 2.3) Mobile Menu Toggler
// 2.4) Circle Badge
// 3. Responsive

// # Variables
@include set-default(
  (
    header: (
      color: false,
      link-color: false,
      link-active-color: false,

      top: (
        padding-top: false,
        padding-bottom: false,
        color: false,
        background: false,
        border-bottom: false,
        font-size: false,
        font-weight: false,
        letter-spacing: false,
      ),
      middle: (
        padding-top: false,
        padding-bottom: false,
        color: false,
        background: false,
        border-bottom: false,
        font-size: false,
        font-weight: false,
        letter-spacing: false,
      ),
      bottom: (
        padding-top: false,
        padding-bottom: false,
        color: false,
        background: false,
        border-bottom: false,
        font-size: false,
        font-weight: false,
        letter-spacing: false,
      ),
      logo: (
        max-width: 97px,
        max-width-mobile: false,
      ),
      separator: (
        color: rgba(0, 0, 0, 0.04),
        space: 10px,
      ),
      social-icon: (
        font-size: 13px,
        size: 2.6rem,
        color: false,
        space: 2px,
      ),
      top-notice: (
        padding: 0.9rem 2rem 1rem,
        font-size: 1.3rem,
        link-background: #151719,
      ),
      icon: (
        size: 2.7rem,
        space: 2.2rem,
        space-mobile: 1.2rem,
      ),
    ),
  )
);

// 1. Header Base
header {
  position: relative;
  @include css(color, header, color);

  .container,
  .container-fluid {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -ms-flex-pack: justify;
    position: relative;
  }

  p {
    margin-bottom: 0;
  }

  a:hover {
    @include css(color, header, link-active-color);
  }
}

.header-icon {
  &:focus,
  &:hover {
    color: $primary-color-dark;
  }

  transition: color 0.3s;

  &:hover {
    color: $primary-color;
  }
}

.minicart-icon {
  transition: border-color 0.3s;

  &:hover {
    border-color: $primary-color;

    &:before {
      border-color: $primary-color;
      transition: border-color 0.3s;
    }
  }
}

@if (get(header, link-color)) {
  .header a {
    color: get(header, link-color);
  }
} @else {
  header a {
    color: inherit;
  }
}

.header {
  &-top {
    @include css(padding-top, header, top, padding-top);
    @include css(padding-bottom, header, top, padding-bottom);
    @include css(border-bottom, header, top, border-bottom);
    @include css(color, header, top, color);
    @include css(background, header, top, background);
    @include css(font-size, header, top, font-size);
    @include css(font-weight, header, top, font-weight);
    @include css(letter-spacing, header, top, letter-spacing);
  }

  &-middle {
    @include css(padding-top, header, middle, padding-top);
    @include css(padding-bottom, header, middle, padding-bottom);
    @include css(border-bottom, header, middle, border-bottom);
    @include css(color, header, middle, color);
    @include css(background, header, middle, background);
    @include css(font-size, header, middle, font-size);
    @include css(font-weight, header, middle, font-weight);
    @include css(letter-spacing, header, middle, letter-spacing);
  }

  &-bottom {
    @include css(padding-top, header, bottom, padding-top);
    @include css(padding-bottom, header, bottom, padding-bottom);
    @include css(border-bottom, header, bottom, border-bottom);
    @include css(color, header, bottom, color);
    @include css(background, header, bottom, background);
    @include css(font-size, header, bottom, font-size);
    @include css(font-weight, header, bottom, font-weight);
    @include css(letter-spacing, header, bottom, letter-spacing);
  }
}

.header-left,
.header-center,
.header-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.header-center,
.header-right {
  margin-left: auto;
}

.header-bottom {
  .header-center {
    width: 100%;
  }

  &:not(.fixed) {
    .header-left,
    .header-right {
      display: none;
    }
  }

  .header-right {
    padding-right: 2.6rem;
    color: $primary-color-dark;
  }

  &.fixed {
    .header-center {
      margin-left: 4.7rem;
      width: auto;
    }

    .main-nav {
      .float-right {
        display: none;
      }
    }

    .logo {
      max-width: 89px;
    }

    .header-search-inline.header-search {
      margin-right: 2.3rem;

      i {
        font-size: 2.3rem;
      }

      .header-search-wrapper {
        display: flex;
        position: absolute;
        margin-top: 10px;
        border: 5px solid $primary-color;
      }

      &:not(.show) .header-search-wrapper {
        display: none;
      }

      .search-toggle,
      .header-search-inline .btn:after {
        display: block;
      }
    }
  }
}

.header-transparent {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1040;
}

// 2. Header Elements

// 2.1) Logo
.logo {
  @include css(max-width, header, logo, max-width);
}

// 2.2) Header Contact
.header-contact {
  align-items: center;
  text-transform: uppercase;

  i {
    margin-right: 1.1rem;
    font-size: 2.9rem;
  }

  img {
    margin-right: 0.7rem;
  }

  h6 {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.3;
    color: #777;
  }

  a {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    white-space: nowrap;
  }
}

// 2.3) Mobile Menu Toggler
.mobile-menu-toggler {
  border: 0;
  background: transparent;
  color: $primary-color-dark;
  padding: 1rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
}

// 2.4) Circle Badge
.badge-circle {
  position: absolute;
  top: 1px;
  right: 0;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  background: #ff5b5b;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: $font-family;
  text-align: center;
}

// 2.5) Social Icons
header {
  .social-icon {
    border-radius: 50%;
    @include css(color, header, social-icon, color);
    @include css(font-size, header, social-icon, font-size);
    @include css(width, header, social-icon, size);
    @include css(height, header, social-icon, size);
    @include css(line-height, header, social-icon, size);

    &:not(:hover):not(:active):not(:focus) {
      background-color: transparent;
    }

    @if get(header, social-icon, space) {
      & + .social-icon {
        margin-left: get(header, social-icon, space);
      }
    }
  }

  // 2.5) Seperator
  .separator {
    height: 20px;
    @if (get(header, separator, color)) {
      border-left: 1px solid get(header, separator, color);
    }
    @if (get(header, separator, space)) {
      margin: 0 get(header, separator, space);
    }
  }
}

// 2.6) Top Notice
.top-notice {
  position: relative;
  @if (get(header, top-notice, padding)) {
    padding: get(header, top-notice, padding) 2rem;
  }
  @include css(font-size, header, top-notice, font-size);
  font-family: $second-font-family;
  line-height: 2;
  letter-spacing: 0.025em;
  overflow: hidden;

  h5 {
    color: inherit;
    font-size: inherit;
    font-weight: 500;
  }

  small {
    font-size: 0.8461em;
    letter-spacing: 0.025em;
    opacity: 0.5;
  }

  a {
    color: inherit;
    font-weight: 700;
  }

  .category {
    display: inline-block;
    padding: 0.3em 0.8em 0.4em;
    @include css(background, header, top-notice, link-background);
    font-size: 1rem;
  }

  .btn-close {
    border: 0;
    font-size: 3rem;
    font-weight: lighter;
    position: absolute;
    right: 0.7rem;
    top: 50%;
    transform: translateY(-50%);
    color: #b2dbf0;
    background: transparent;
  }

  .mfp-close {
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg) translateZ(0);
    color: inherit;
    opacity: 0.7;
    z-index: 10;

    &:hover {
      opacity: 1;
    }
  }
}

// 2.7) Header Icon
.header-icon {
  &:not(:last-child) {
    @include css(margin-right, header, icon, space);
  }

  i {
    @include css(font-size, header, icon, size);
  }
}

.minicart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 20px;
  border: 2px solid $primary-color-dark;
  border-radius: 0 0 5px 5px;
  position: relative;
  opacity: 0.9;
  margin: 6px 3px 0;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    border: 2px solid;
    border-color: inherit;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    left: 50%;
    top: -8px;
    margin-left: -7px;
    width: 15px;
    height: 11px;
  }
}

.cart-count {
  z-index: 1;
}

// 3. Responsive

@include mq(lg) {
  .mobile-menu-toggler {
    display: none;
  }
}

@include mq(lg, max) {
  .main-nav {
    display: none;
  }

  .minicart-icon {
    width: 24px;
  }

  .header-search i {
    font-size: 2.4rem;
  }
}

@include mq(md, max) {
  .header .separator {
    display: none;
  }

  .logo {
    max-width: 100px;
  }
}

@include mq(sm, max) {
  .top-notice {
    line-height: 1.5;
  }

  .logo {
    @include css(max-width, header, logo, max-width-mobile);
  }

  .header-icon {
    &:not(:last-child) {
      @include css(margin-right, header, icon, space-mobile);
    }
  }
}
