.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    margin: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    color: $nav-color;
    font-size: $nav-font-size;

    [class*="owl-"] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $nav-color;
      margin: $nav-margin;
      font-size: $nav-font-size;
      padding: $nav-padding;
      background: $nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;

      i:before {
        margin: 0;
        width: auto;
      }

      &:not(.disabled):hover {
        background: $nav-background-hover;
        color: $nav-color-hover;
        text-decoration: none;
      }
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }

    .disabled {
      opacity: $nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 2.5rem;
  }

  .owl-dots {
    line-height: 1;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        position: relative;
        width: $dot-width;
        height: $dot-height;
        margin: $dot-margin;
        background: $dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: all 0.3s ease;
        border: 0.2rem solid $dot-border;
        border-radius: $dot-rounded;

        &:before {
          content: "";
          display: block;
          width: 0.7rem;
          height: 0.7rem;
          background-color: transparent;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -0.35rem;
          margin-left: -0.35rem;
          background-color: $dot-border-active;
          opacity: 0;
          visibility: hidden;
          transform: scale(0);
          transition: all 0.3s;
          border-radius: 50%;
        }
      }

      &.active,
      &:hover {
        span {
          border-color: $dot-border-active;
          background: $dot-background-active;
        }
      }

      &.active {
        span {
          &:before {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
          }
        }
      }
    }
  }

  &.owl-theme-light {
    .owl-dots {
      .owl-dot {
        span {
          border-color: $dot-border-light;
          background: $dot-background-light;

          &:before {
            border-color: $dot-border-light;
          }
        }

        &.active,
        &:hover {
          span {
            border-color: $dot-border-light-active;
            background: $dot-background-light-active;
          }
        }

        &.active {
          span {
            &:before {
              border-color: $dot-border-light-active;
            }
          }
        }
      }
    }
  }
}
