.dashboard-content {
  .btn-link {
    display: inline;
    padding: 0;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    vertical-align: top;
    white-space: normal;
  }

  p {
    line-height: 1.75;

    &:first-child {
      margin-top: -0.2rem;
      margin-bottom: 2.4rem;
    }
  }

  .feature-box {
    padding-top: 3.5rem;
    margin-bottom: 2rem;
    border: 2px solid #e7e7e7;

    i {
      margin-bottom: 2.5rem;
      color: #d3d3d4;
      font-size: 6rem;
      transition: transform 0.35s;
    }

    &:hover {
      cursor: pointer;

      i {
        transform: scale(1.15);
        transition: transform 0.35s;
      }
    }
  }
}

.widget-dashboard {
  h2 {
    margin-top: -1px;
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
  }

  .list {
    border-bottom: none;

    a {
      padding: 1rem 0 1.1rem;
      color: #777;
      letter-spacing: -0.025em;
      font-size: 1.4rem;

      &:hover,
      &:focus {
        background: transparent;
      }

      &.active {
        color: #222524;
        font-weight: 700;
      }
    }
  }

  li:last-child a {
    border-bottom: 0;
  }

  li {
    padding: 8px 0 8px 0;

    &:before {
      display: none;
    }
  }

  .nav-tabs .nav-item:last-child {
    margin-right: 3.5rem;
  }
}

.account-container {
  margin-top: 4.2rem;
  margin-bottom: 4rem;
}

.nav-tabs.list {
  .nav-item {
    padding: 0;

    .nav-link {
      padding: 1.3rem 0 1.5rem;
      font-family: $font-family;
      text-transform: capitalize;
      font-size: 1.4rem;
      border: none;
      font-weight: 400;
      color: #777;

      &:hover {
        cursor: pointer;
      }
    }

    .nav-link.address,
    &.active .nav-link {
      font-weight: 700;
      color: $primary-color-dark;
    }
  }
}

@include mq("sm", max) {
  .dashboard-content {
    .feature-box h3 {
      font-size: 1.3rem;
    }
  }
}
