.minipopup-area {
  left: 30px;
  bottom: 30px;
  z-index: 2000;
}

.minipopup-box {
  left: -8rem;
  width: 300px;
  padding: 2rem 2rem 0;
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  transition:
    opacity 0.3s,
    left 0.3s ease-out,
    transform 0.3s;

  .product {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  figure {
    max-width: 60px;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .product-detail {
    font-family: $second-font-family;
    font-weight: 500;

    .product-name {
      color: #222529;
      font-size: 12px;
      font-weight: 700;
      &:hover {
        color: $primary-color;
      }
    }

    p {
      font-size: 12px;
      letter-spacing: -0.025em;
      margin-bottom: 0;
    }
  }

  .product-action {
    .btn {
      min-width: 120px;
      letter-spacing: 0.025em;
      font-size: 11.2px;
      padding: 11.2px 22.4px;
    }

    .btn.viewcart {
      background-color: #efefef;
      color: #000;
    }

    .btn.checkout {
      float: right;
    }
  }
}

// Wishlist Popup
.wishlist-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-width: 4px 0 0;
  font-weight: 600;
  line-height: 1.5;
  padding: 15px 20px;
  width: 250px;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.35s,
    visibility 0.35s;
  z-index: 100;

  .wishlist-popup-msg {
    font-weight: 600;
    line-height: 1.6;
    text-align: center;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 1071;
  }
}

// Login Popup
.login-popup {
  .mfp-content {
    margin-top: 2.1rem;
    max-width: 872px;
    background-color: #fff;
  }

  .btn-regist {
    margin-top: 3.6rem;
    font-size: 1.6rem;
    letter-spacing: -0.025em;
  }

  .form-footer-right {
    margin-bottom: 0.6rem;
  }

  .form-input {
    height: 42px;
  }

  .container {
    padding: 3.9rem 3.6rem 4.7rem;
  }

  .title {
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.45;
    margin-bottom: 0.9rem;
  }

  form {
    display: block;
  }

  label {
    color: #777;
    font-family: $font-family;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.57;
    margin-bottom: 0.6rem;
  }

  .form-footer {
    margin: 1rem 0 2.1rem;

    .custom-control {
      margin: 0 0 0 auto;
      font-size: 1.3rem;
      padding-left: 2.5rem;
    }
  }

  .forget-password {
    color: var(--primary-color);
    font-size: 1.4rem;
    font-family: $font-family;
    font-weight: 600;
  }

  .btn-block {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    padding: 1.5rem 2.4rem;
    letter-spacing: -0.02em;
  }

  .form-control {
    padding-left: 2.5rem;

    &:hover {
      outline: none;
    }
  }

  .custom-control-label {
    margin-top: 2px;
    font-size: 1.2rem;
  }
}

// Newsletter popup
.newsletter-popup {
  position: relative;
  max-width: 740px;
  margin-right: auto;
  margin-left: auto;
  padding: 64px 40px;
  border-radius: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);

  .mfp-close {
    color: #333;
  }

  .custom-checkbox .custom-control-label:after {
    left: 5px;
  }
}

.newsletter-popup,
.login-popup,
.video-modal,
.product-quick-view {
  margin-top: -2rem;
  transition: margin-top 0.35s;

  &.ReactModal__Content--after-open {
    margin-top: 0;
  }
}

.close-modal {
  .newsletter-popup,
  .login-popup,
  .video-modal,
  .product-quick-view {
    margin-top: -2rem;
    transition: margin-top 0.35s;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.35s,
    visibility 0.35s;

  &.ReactModal__Overlay--after-open {
    opacity: 1;
    visibility: visible;
  }

  &.close-modal {
    opacity: 0;
    visibility: hidden;
  }
}

.mfp-bg {
  background-color: rgba(119, 119, 119, $mfp-overlay-opacity);
}

button.mfp-close {
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
  opacity: 0.65;
  cursor: pointer;
  background: transparent;
  border: 0;
  text-indent: -9999px;
  transform: rotateZ(45deg);
  color: #838383;

  &:hover {
    opacity: 1;
  }

  .mfp-image-holder & {
    width: 41px;
    color: #fff;
    text-align: left;
  }

  &:after {
    content: "";
    position: absolute;
    height: 17px;
    top: 12px;
    left: 20px;
    border-left: 1px solid;
  }

  &:before {
    content: "";
    position: absolute;
    width: 17px;
    top: 20px;
    left: 12px;
    border-top: 1px solid;
  }
}

.newsletter-popup-content {
  max-width: 353px;

  .form-control {
    height: auto;
    padding: 8px 12px 8px 22px;
    border-radius: 3rem 0 0 3rem;
    font-size: 1.36rem;
    line-height: 2.4;
    border: none;
    background-color: #f4f4f4;

    &::-ms-input-placeholder {
      color: #9d9d9d;
    }

    &::placeholder {
      color: #9d9d9d;
    }
  }
  .btn {
    margin-left: -1px;
    padding: 0 32px 0 25px;
    border-radius: 0 3rem 3rem 0;
    font-size: 1.28rem;
    font-family: $font-family;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
  }
}

.logo-newsletter {
  display: inline-block;
  max-width: 111px;
  height: auto;
}

.newsletter-popup h2 {
  margin: 24px 0 5px;
  color: #313131;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

.newsletter-popup p {
  font-size: 1.4rem;
  line-height: 1.85;
  letter-spacing: -0.02em;
  margin-bottom: 2.4rem;
}

.newsletter-popup form {
  margin: 0 0 2.7rem;
}

.newsletter-popup .custom-control {
  margin: 0 0 4px 1px;
  padding-left: 2.5rem;
}

.newsletter-subscribe {
  font-size: 1.1rem;
  text-align: left;

  .checkbox {
    margin: 1.5rem 0;
  }

  input {
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  label {
    margin-top: 2px;
    color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: $font-family;
    text-transform: none;
  }
}

.mfp-newsletter.mfp-removing {
  transition: opacity 0.35s ease-out;
  opacity: 0;
}

// Popup Configuration
.mfp-ready .mfp-preloader {
  display: none;
}

.mfp-zoom-out-cur {
  .mfp-bg {
    opacity: 0.8;
    background-color: #0b0b0b;
  }

  .mfp-counter {
    color: #fff;
  }

  .mfp-arrow-right:before {
    border-left: 0;
  }

  .mfp-arrow-left:before {
    border-right: 0;
  }
}

.mfp-ajax-product.mfp-bg,
.login-popup.mfp-bg {
  opacity: 0.6;
  background-color: transparent;
}

.mfp-ajax-product .product-single-container {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
}

// content at start
.mfp-wrap .mfp-content {
  transition: all 0.35s ease-out;
  opacity: 0;
}

.login-popup.mfp-wrap {
  .mfp-content {
    max-width: 525px;
  }
}

.mfp-ajax-product.mfp-wrap {
  .mfp-content {
    max-width: 931px;
  }
}

// content animate it
.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

// content animate out
.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-ajax-product {
  z-index: 1058;
}

.mfp-bg,
.mfp-wrap {
  &.mfp-newsletter,
  &.login-popup {
    z-index: 1058;
  }
}

@include mq(lg, max) {
  .mfp-container {
    padding: 0.6rem;
  }
}

@include mq(md, max) {
  .newsletter-subscribe label {
    margin-left: 3px;
  }
}

@include mq(xs, max) {
  .minipopup-area {
    right: 10px;
  }

  .minipopup-box {
    width: 290px;
  }

  .newsletter-popup {
    padding: 54px 30px;
  }

  .Toastify__toast-container {
    left: auto !important;
  }

  .Toastify__toast {
    margin-bottom: 1rem !important;
  }
}

@include mq(md) {
  .login-popup .col-md-6 {
    padding: 0 2rem;

    &:first-child {
      border-right: 1px solid #f5f6f6;
    }
  }
}
