// Button mixins - variants
@mixin button-variant(
  $color,
  $background,
  $border,
  $color-hover: $color,
  $hover-background: lighten($background, 8%),
  $hover-border: lighten($border, 8%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  border-color: $border;
  background-color: $background;
  color: $color;
  box-shadow: none;

  &:hover,
  &:focus,
  &.focus {
    border-color: $hover-border;
    background-color: $hover-background;
    color: $color-hover;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    border-color: $border;
    background-color: $background;
    color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $active-border;
    background-color: $active-background;
    color: $color;
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: $color,
  $active-background: $color,
  $border-color: $color,
  $active-border: $color
) {
  border-color: $border-color;
  background-color: transparent;
  background-image: none;
  color: $color;

  &:hover,
  &.focus {
    border-color: $active-border;
    background-color: $active-background;
    color: $color-hover;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $active-border;
    background-color: $active-background;
    color: $color-hover;
  }
}
