// Vertical Menu

// Menu - Vertical

// # Variables
@include set-default(
  (
    menu: (
      vertical: (
        color: false,
        background: false,
        active-color: #fff,
        active-background: $primary-color,
      ),
    ),
  )
);

.menu-vertical {
  ul,
  .megamenu {
    top: 0;
    left: 100%;
    margin-left: -1px;
    border-top: 0;
  }

  &.sf-arrows {
    > li {
      > .sf-with-ul {
        &:before {
          top: 50%;
          bottom: auto;
          left: calc(95% - 12px);
          margin: -10px 0 0;
          border-width: 10px 12px 10px 0;
          border-color: transparent;
          border-right-color: #fff;
          transition: 0.2s;
        }

        &:after {
          content: "\e81a";
          position: absolute;
          right: 2.8rem;
          color: #838b90;
          font-size: 1.5rem;
        }
      }

      &.show > .sf-with-ul {
        &:before {
          left: calc(100% - 12px);
        }

        &:after {
          color: inherit;
        }
      }

      &:hover > .sf-with-ul {
        &:after {
          color: inherit;
        }
      }
    }
  }

  // Nolink
  .nolink {
    font-size: 1.3rem;
    font-weight: 700;
  }

  // Main Item
  > li {
    float: none;
    margin: 0;
    padding: 0 1.8rem 0 1.6rem;
    @include css(background, menu, vertical, background);

    &:not(:first-child) {
      border-top: 1px solid #fff;

      > a {
        margin-top: -1px;
        border-top: 1px solid #e7e7e7;
      }
    }

    > a {
      display: block;
      padding: 1.2rem 1rem 1.4rem 0.5rem;
      font-size: 1.4rem;
      font-weight: 600;
      text-transform: capitalize;
      transition: none;
      @include css(color, menu, vertical, color);
    }

    i {
      position: relative;
      margin-right: 8px;
      top: 1px;
    }
  }

  > li:hover,
  > li.show,
  > li.active {
    @include css(background, menu, vertical, active-background);

    > a {
      border-bottom-color: transparent;
      @include css(color, menu, vertical, active-color);
    }

    + li > a {
      border-top-color: transparent;
    }
  }
}

.menu-custom-block {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 0.9rem;
  a {
    display: block;
    position: relative;
    padding: 0 15px;
    text-transform: uppercase;
    font-family: $second-font-family;
    font-size: 12px;
    font-weight: 700;
    line-height: 32px;

    &:not(:hover) {
      color: #465157;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.menu-item-sale {
  text-align: center;
  a {
    display: inline-block;
    margin: 7px 0px 20px;
    padding: 1.6rem 4rem;
    background-color: #f4f4f4;
    color: $secondary-color;
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.toggle-menu-wrap .side-nav {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.side-menu-wrapper {
  position: relative;
}

.side-menu-title.cursor-pointer {
  cursor: pointer;

  & + .side-nav {
    display: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 4px 4px;
  }
}

@include mq(xl, max) {
  .menu-item-sale a {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
@include mq(sm, max) {
  .menu-custom-block {
    display: none;
  }
}
