// Demo 27 Index Page

// Elements
section:not(.popular-products) {
  overflow: hidden;
}

.font5 {
  font-family: "Segoe Script", "Savoye LET" !important;
}

.text-light {
  color: #999 !important;
}

.section-title {
  margin-bottom: 3.7rem;
  color: #313131;
  font-size: 1.6rem;
  letter-spacing: 0.15em;
  line-height: 23px;
  text-align: center;
}

.heading-border {
  display: flex;
  align-items: center;
  position: relative;

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-top: 5px solid $primary-color-dark;
  }

  &:before {
    margin-right: 15px;
  }

  &:after {
    margin-left: 15px;
  }
}

.btn-teritary {
  @include button-variant(#fff, #323232, #323232);
}

.btn-lg {
  padding: 1.125em 4em;
}

.btn-light {
  color: $primary-color-dark;
  background-color: #fff;
  border-color: #fff #fff #e6e6e6;

  &:hover,
  &:active,
  &:focus {
    color: $body-text;
    background-color: #fff;
    border-color: #fff;
  }
}

small {
  font-size: 50%;
  line-height: 1.6;
  vertical-align: top;
  font-weight: inherit;
}

// Intro section
.intro-section {
  position: relative;
  margin-bottom: 3.4rem;
  border-bottom: 1px solid #e7e7e7;

  .owl-carousel .owl-item img {
    width: auto;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 61%;
    display: block;
    background: #f7f6f7;
  }
}

.home-slider .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;

  .owl-prev,
  .owl-next {
    width: 30px;
    height: 30px;
  }

  .owl-prev {
    left: -30px;
  }

  .owl-next {
    right: -30px;
  }
}

.home-slide {
  background: transparent;

  .media-with-lazy figure::before {
    padding-top: 38.445%;
    background: transparent;
  }

  .col-lg-4,
  .col-lg-5 {
    padding: 4.8rem 0;
  }

  .col-lg-7,
  .col-lg-8 {
    padding: 0 0 4.8rem 5%;
  }

  .with-bg {
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  h4 {
    font-size: 2.125em;
    font-weight: 500;
  }

  h2 {
    font-size: 4.3125em;
  }

  h3 {
    padding-bottom: 2.4rem;
    font-size: 2.4375em;
    letter-spacing: 0.005em;
  }

  .btn {
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.15em;
  }

  img.owl-lazy {
    display: inline-block;
    width: auto;
  }
}

.content-box {
  h5 {
    font-size: 1.125em;
  }

  b {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 0.08em;
    font-size: 2em;
  }

  h6 {
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  p {
    font-size: 1.1rem;
    line-height: 2.5rem;
  }
}

// Popular proudcts
.products-slider {
  margin-bottom: 1.6rem;
  overflow: hidden;
}

.categories-slider {
  background: #f4f4f4;

  .owl-stage-outer {
    margin-left: 2px;
    margin-right: 2px;
  }

  .product-category {
    background: #fff;
    margin: 2px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 60px;
    margin-right: 15px;
    border-radius: 0;
  }

  .category-content {
    padding: 0;

    h3 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

// Banners Section
.banners-section {
  .row-sm {
    margin-left: -5px;
    margin-right: -5px;

    > [class*="col-"] {
      padding: 5px;
    }
  }
}

.banner2 {
  padding-top: 2.5%;
  font-size: 0.6666vw;

  h4 {
    margin-left: 37%;
    font-size: 2.1875em;
    font-weight: 500;
  }

  h2 {
    margin-left: 42%;
    margin-right: 2.7rem;
    font-size: 4.5em;
    letter-spacing: -0.5px;
  }

  h3 {
    margin-left: 46%;
    margin-right: 22%;
    font-size: 2.65em;
    letter-spacing: -0.5px;
  }

  .btn {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.15em;
  }
}

// Trendy Section

.banner3,
.banner4 {
  padding: 2.4rem 0;

  h3 {
    font-size: 1.71875em;
    letter-spacing: 0.005em;
  }

  h2 {
    font-size: 3.0625em;
    letter-spacing: -0.5px;
  }

  .btn {
    padding: 1.125em 2.1em;
    font-size: 0.875em;
    font-weight: 600;
    letter-spacing: 0.15em;
  }
}

.banner4 h3 {
  font-size: 2.1875em;
}

// Responsive
@include mq(sm) {
  .home-slide h5 {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }

  .banner3,
  .banner4 {
    padding: 1.6rem 0;
  }
}

@include mq(lg) {
  .home-slide {
    .col-lg-4,
    .col-lg-5 {
      padding-left: 4.8rem;
    }

    .col-lg-7,
    .col-lg-8 {
      padding-top: 4.8rem;
    }

    .content-info {
      padding-left: 2.4rem;
    }
  }

  .banner2 .btn {
    font-size: 12.8px;
  }
}

@include mq(xl) {
  .banner3,
  .banner4 {
    font-size: 0.83333vw;
  }

  .products-slider {
    .product-default {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

@include mq(xl, max) {
  .home-slide {
    h2 {
      font-size: 4em;
    }

    h3 {
      font-size: 2em;
    }
  }
}

@include mq(lg, max) {
  .home-slide {
    .col-lg-8 {
      background: none !important;
    }

    .media-with-lazy figure::before {
      padding-top: 53.445%;
    }
  }

  .banner2 .btn {
    padding: 1.125em 3em;
  }
}

@include mq(md, max) {
  .banner2 {
    min-height: 50vw;
    font-size: 1vw;

    .heading-border:before,
    .heading-border:after {
      border-top-width: 2px;
    }
  }

  .banner3,
  .banner4 {
    font-size: 1.2rem;
  }
}

@include mq(xs, max) {
  .home-slide .media-with-lazy figure::before {
    padding-top: 53%;
  }
}
