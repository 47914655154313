// Modals
.modal {
  form {
    margin: 0;

    .form-group {
      max-width: 480px;
    }

    .form-control {
      max-width: 100%;
    }
  }
}

.modal-body {
  padding: 1.5rem;
}

.modal-wrapper {
  outline: none;
}

.modal-content {
  border-radius: 0;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
}

.modal-header,
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.modal-title {
  font-weight: 400;
}

.close {
  font-size: 2.2rem;
}

.video-modal {
  position: relative;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  width: 90%;
  height: 30vw;
  background: #f4f4f4;

  .modal-close {
    position: absolute;
    font-size: 3.3rem;
    color: #fff;
    font-weight: 300;
    border: 0;
    opacity: 0.7;
    right: -8px;
    text-align: -4rem;
    top: -4rem;
    background: transparent;
    cursor: pointer;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

@include mq(md, max) {
  .video-modal {
    height: 50vw;
  }
}

@include mq(sm) {
  .modal-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-body {
    max-height: calc(100vh - 210px);
    padding-top: 2rem;
    overflow-y: auto;
  }

  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: 2em;
    padding-left: 2rem;
  }
}

@include mq(lg) {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@include mq(xs, max) {
  .modal-open,
  .modal-open .modal {
    padding-right: 0 !important;
  }
}
