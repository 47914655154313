// Social Icons

// # Contents
// 1. Social Icon
// 2. Live Social Icons

// # Variables

// 1. Social Icon

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: $primary-color;
  font-size: 14px;
  line-height: 3.2rem;
  text-align: center;
  text-decoration: none;
  opacity: 1;

  & + .social-icon {
    margin-left: 0.6rem;
  }

  .social-icons &:hover,
  .social-icons &:focus {
    color: #fff;
    text-decoration: none;
    opacity: 0.85;
  }

  // 2. Live Social Icons

  &.social-facebook {
    background-color: #3b5a9a;

    &:hover {
      border-color: rgba(59, 90, 154, 0.85);
    }
  }

  &.social-twitter {
    background-color: #1aa9e1;

    &:hover {
      border-color: rgba(26, 169, 225, 0.85);
    }
  }

  &.social-instagram {
    background-color: #7c4a3a;

    &:hover {
      border-color: rgba(124, 74, 58, 0.85);
    }
  }

  &.social-linkedin {
    background-color: #0073b2;

    &:hover {
      border-color: rgba(0, 115, 178, 0.85);
    }
  }

  &.social-gplus {
    background-color: #dd4b39;

    &:hover {
      border-color: rgba(221, 75, 57, 0.85);
    }
  }

  &.social-mail {
    background-color: #dd4b39;

    &:hover {
      border-color: rgba(221, 75, 57, 0.85);
    }
  }
}
