.account-content {
  margin-top: 2.1rem;

  label {
    margin-bottom: 0.7rem;
    color: $body-text;
    font-family: $font-family;
    font-size: 1.4rem;
    font-weight: 400;
  }

  form {
    margin-bottom: 4px;
  }

  .form-input {
    margin-bottom: 1.7rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-color: #e7e7e7;
    line-height: 32px;
  }

  .form-group {
    margin-bottom: 1.7rem;

    p {
      font-size: 1.3rem;
      margin-top: 3px;
    }
  }

  .form-control {
    padding-top: 2px;
    padding-left: 1.2rem;
    font-size: 1.4rem;
    height: 50px;
    border-color: rgba(0, 0, 0, 0.09);
    letter-spacing: -0.015em;
  }

  .btn {
    font-family: $font-family;
    font-size: 1.5rem;
  }
}

.change-password {
  padding: 2.6rem 3rem 2.2rem;
  border: 2px solid #e7e7e7;

  h3 {
    font-size: 1.6rem;
    font-family: $font-family;
  }

  .btn {
    padding: 1.6rem 4.5rem 1.5rem;
    letter-spacing: -0.02em;
  }
}

.page-header,
.about {
  .breadcrumb-item + .breadcrumb-item:before {
    padding-right: 1.1rem;
  }
}

.account-container {
  .nav-link {
    letter-spacing: -0.025em;
  }

  .nav-tabs.list .nav-item {
    margin-right: 3.5rem;
    outline: none;
  }

  .sidebar {
    height: auto;
  }
}

.show-content,
.hide-content {
  &.fade:not(.show) {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
}
