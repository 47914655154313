.lazy-load-image-background {
  position: relative;
  display: block !important;
  width: 100%;
  z-index: -1;

  span {
    display: block !important;
  }

  img {
    width: 100%;
  }

  &.product-image {
    position: static;
  }
}

.lazy-load-image-background.lazy-load-image-loaded {
  display: inline !important;
  z-index: 3;
}

.lazy-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #f4f4f4;
}

.media-with-lazy {
  &.post figure::before {
    padding-top: 74.65%;
  }

  span.product-image-hover img {
    opacity: 0;
  }

  figure {
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      transition: all 0.5s;
      object-fit: cover;
    }

    .lazy-load-image-background {
      position: static;
    }

    .lazy-overlay + .lazy-load-image-background {
      img {
        opacity: 1;
      }
    }

    .lazy-load-image-background.lazy-load-image-loaded {
      z-index: 0;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
      width: 100%;
      background-color: #f4f4f4;
    }
  }

  &:hover figure {
    .lazy-overlay + .lazy-load-image-background {
      img {
        opacity: 0;
      }
    }

    .lazy-load-image-background:last-child {
      img {
        opacity: 1;
      }
    }
  }
}
